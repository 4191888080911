import React from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import SingleProduct from "../components/single-product-page/singleProduct"

import lipInfusionsNourishProduct from "../images/product-and-package/lip-infusions-nourish-bleed.svg"
import lipInfusionsNourishImage from "../images/products/lip-infusions-nourish-large.png"

// related product images
import lipInfusionsHydrationProduct from "../images/product-and-package/lip-infusions-hydration.jpg"
import lipInfusionsSoothingProduct from "../images/product-and-package/lip-infusions-soothing.jpg"
import ocadoLogo from "../images/stockists/ocado.png"
import tescoLogo from "../images/stockists/tesco.png"
import superdrugLogo from "../images/stockists/superdrug.png"
import morrisonsLogo from "../images/stockists/morrisons.png"
import amazonLogo from "../images/stockists/amazon.png"
import everydayIcon from "../images/icons/product-icon/everyday.svg"
import outdoorsIcon from "../images/icons/product-icon/outdoors.svg"
import coldIcon from "../images/icons/product-icon/winter.svg"
import nightIcon from "../images/icons/product-icon/overnight.svg"

import BSSilverAward from "../images/awards/BS-Award-Silver.png"

const product = {
  image: lipInfusionsNourishProduct,
  productImage: lipInfusionsNourishImage,
  productImageStyle: "bleed",
  title: <h1 className="product-overview__heading">Lip Infusions - Nourish</h1>,
  alt: "Lip Infusions - Nourish.",
  subTitle: (
    <h2 className="product-overview__sub-heading">
      <br />
      With Natural Beeswax and Coconut Oil to moisturise and protect lips
      <br />
    </h2>
  ),
  disclaimer: "",
  awards: [
    { title: "SILVER and BEAUTY STEAL in LIP BALM", img: BSSilverAward },
  ],
  icons: [
    { title: "everyday", icon: everydayIcon },
    { title: "outdoors", icon: outdoorsIcon },
    { title: "cold weather", icon: coldIcon },
    { title: "at night", icon: nightIcon },
  ],
  overviewCopy: (
    <div>
      <br />
      <p>
        Blistex Lip Infusions Nourish has an advanced lip care formula that
        delivers concentrated nourishment for luxuriously smooth lips. Infused
        with a blend of nutrient-enriched botanical oils and Vitamin E to soften
        without leaving a greasy feel.
      </p>
      <ul>
        <li>
          Ceramide-enhanced formula helps retain moisture, leaving lips supple
          and smooth
        </li>
        <li>
          With Natural Beeswax and Coconut Oil to moisturise and protect lips
        </li>
        <li>Contains SPF 15 to protect against the sun's harmful UVB rays</li>
      </ul>
    </div>
  ),
  listID: "",
  stockists: [
    {
      stockistName: "Amazon",
      stockistLink: "https://www.amazon.co.uk/dp/B09HSC2K85",
      stockistImage: amazonLogo,
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Superdrug",
      stockistImage: superdrugLogo,
      stockistLink:
        "https://www.superdrug.com/Skin/Face-Skin-Care/Lip-Care/Lip-Balms/Blistex-Lip-Infusions---Nourish/p/805974?singleResultSearchPage=true",
      classModifier: "",
      showLink: "true",
    },
  ],
  whenToUseCopy: (
    <div>
      <p>
        Simply twist the stick and apply evenly to your lips. Re-apply as often
        as you like, especially if you're out and about in dry, cold or sunny
        weather.
      </p>
    </div>
  ),
  ingredients: (
    <p>
      hydrogenated coconut oil, euphorbia cerifera (candelilla) wax, homosalate,
      cera alba, phenyl trimethicone, ethylhexyl salicylate, brassica
      campestris/aleurites fordi oil copolymer, aroma, tocopheryl acetate,
      bis-diglyceryl polyacyladipate-2, C10-30 cholesterol/lanosterol esters,
      dimethicone, copernicia cerfera (carnauba) wax, alumina, amyl cinnamal,
      arachidyl alcohol, arachidyl glucoside, behenyl alcohol, C12-15 alkyl
      benzoate, caprylic/capric triglyceride, ceramide NG, limonene, linalool,
      palmitoyl hexapeptide-12, phenoxyethanol, polyhydroxystearic acid,
      saccharin, stearic acid, tribehenin, CI 778791
    </p>
  ),
  faqs: [
    {
      question: "Does Lip Infusions Nourish contain a sun protection factor?",
      answer: "Yes, SPF15.",
    },
    {
      question:
        "Does this product contain any ingredients derived from animals?",
      answer: "Yes, beeswax.",
    },
    {
      question: "Do you sell this product online?",
      answer:
        "Lip Infusions Nourish is available to buy online through key retailers. Please click on the where to buy link on each product page.",
    },
  ],
  relatedProducts: [
    {
      productName: "Lip Infusions - Hydration.",
      productLink: "/lip-infusions-hydration",
      productImage: lipInfusionsHydrationProduct,
    },
    {
      productName: "Lip Infusions - Soothing.",
      productLink: "/lip-infusions-soothing",
      productImage: lipInfusionsSoothingProduct,
    },
  ],
}

const lipInfusionsNourish = () => (
  <Layout noHeaderInLayout={false} makeFooterSticky={false}>
    <SEO title="Lip Infusions - Nourish." />
    <SingleProduct data={product} inStock={true} outOfStockMsg={null} />
  </Layout>
)

export default lipInfusionsNourish
